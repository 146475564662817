import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { getCategoryColor } from '../components/context/AppContext';
import { ArtworkLandingPage } from '../components/pagelayout/artworks/ArtWorkLandingPage';

export const pageQuery = graphql`
  query RobArtworkPageQuery {
    landing: wpPage(slug: { eq: "rob-artwork" }) {
      artworkLanding {
        buy
        buyUrl
        artworkDisplayTitle
        artworkIntroduction
        artworkIntroAudioAlternative {
          mediaItemUrl
          localFile {
            publicURL
          }
        }
        artworkInstructions
        artworkInstructionsAudioAlternative {
          mediaItemUrl
          localFile {
            publicURL
          }
        }
        artworkImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        visitArtworkTitle
        visitArtworkLink {
          url
        }
      }
    }
  }
`;

const RobLandingPage = ({ data }) => {
  const landing = data.landing;
  return (
    <>
      <SEO title="Rob Artwork Landing Page" />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor('The world as we see it'),
        }}
      />
      <ArtworkLandingPage landing={landing} />
    </>
  );
};
export default RobLandingPage;
