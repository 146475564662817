import React from 'react';
import Img from 'gatsby-image';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { Wrapper } from '../../../layout/Wrapper';
import { InternalExternalLink } from '../../../common/InternalExternalLink';
import { HandwrittenTitle } from '../../../common/HandwrittenTitle';
import { Breadcrumb } from '../../../common/Breadcrumb';
import { ExtraItem } from '../../../pagelayout/article/ExtraItems/ExtraItem';
import styles from './ArtworkLandingPage.module.scss';

const ArtworkLandingPage = ({ landing }) => {
  const { artworkLanding } = landing;

  return (
    <>
      <Wrapper className={styles.breadcrumb} wrapped>
        <Breadcrumb
          items={[
            {
              to: '/',
              text: 'Home',
            },
            {
              to: `/a-different-kind-of-future`,
              text: 'A Different Kind of Future',
            },
            artworkLanding.artworkDisplayTitle,
          ]}
        />
      </Wrapper>
      <Wrapper id="intro" wrapped padded className={styles.pageWrapper}>
        <div className={styles.pageHeader}>
          <div className={styles.intro}>
            <div>
              <HandwrittenTitle className={styles.title} tagEl="h1" title={artworkLanding.artworkDisplayTitle} />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: artworkLanding.artworkIntroduction,
              }}
            />
            <div className={styles.listenBlock}>
              <HubAudioPlayer src={artworkLanding.artworkIntroAudioAlternative?.localFile?.publicURL} label="Listen" />
            </div>
          </div>
          <div>
            <div className={styles.imageThumbnail}>
              <Img
                fluid={artworkLanding.artworkImage?.localFile?.childImageSharp?.fluid}
                alt={artworkLanding.artworkImage?.altText}
              />
            </div>
            <div className={styles.visitTitle}>
              <InternalExternalLink to={artworkLanding.visitArtworkLink.url} newTab>
                {artworkLanding.visitArtworkTitle}
              </InternalExternalLink>
            </div>
          </div>
        </div>
        <div className={styles.instructionsBlock}>
          <div
            dangerouslySetInnerHTML={{
              __html: artworkLanding.artworkInstructions,
            }}
          />
          <div className={styles.listenBlock}>
            <HubAudioPlayer
              src={artworkLanding.artworkInstructionsAudioAlternative?.localFile?.publicURL}
              label="Listen"
            />
          </div>
          {artworkLanding.buyUrl && (
            <div>
              <ExtraItem
                className={styles.shop}
                to={artworkLanding.buyUrl}
                text={artworkLanding.buy}
                category="shop"
                size="large"
              />
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export { ArtworkLandingPage };
