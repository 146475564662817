import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InternalExternalLink } from '../InternalExternalLink';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ items, className }) => {
  return (
    <ul className={classNames(styles.breadcrumb, className)}>
      {items.map((item, idx) => {
        if (typeof item === 'string') {
          return <li key={idx}>{item}</li>;
        }

        return (
          <li key={idx}>
            <InternalExternalLink to={item.to}>{item.text}</InternalExternalLink>
          </li>
        );
      })}
    </ul>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        to: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      PropTypes.string,
    ]),
  ).isRequired,
  className: PropTypes.string,
};

export { Breadcrumb };
