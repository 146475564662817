import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InternalExternalLink } from '../../../../common/InternalExternalLink';
import { HubAudioPlayer } from '../../../../common/HubAudioPlayer';
import { Icon } from '../../../../common/Icon/Icon';

import styles from './ExtraItem.module.scss';

const ExtraItem = ({ to, text, file, category, className }) => {
  const cls = classNames(styles.item, category, className);

  const icon = useMemo(() => {
    switch (category) {
      case 'video':
        return 'video';
      case 'article':
        return 'article';
      case 'survey':
        return 'survey';
      case 'pdf':
        return 'pdf';
      case 'audio':
        return 'audio';
      case 'artwork':
        return 'image';
      case 'easyread':
        return 'pdf-accessible';
      case 'shop':
        return 'shop';
      default:
        return false;
    }
  }, [category]);

  if (category === 'audio') {
    return (
      <div className={cls}>
        <HubAudioPlayer className={styles.icon} src={file} />
        <span className={styles.text}>{text}</span>
      </div>
    );
  }

  if (!file && !to) {
    return null;
  }

  return (
    <InternalExternalLink to={file || to} className={cls}>
      {icon && <Icon className={styles.icon} name={icon} />}
      <span className={styles.text}>{text}</span>
    </InternalExternalLink>
  );
};

ExtraItem.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  file: PropTypes.string,
  category: PropTypes.string.isRequired,
};

export { ExtraItem };
